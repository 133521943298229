// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-marketing-blog-post-blog-post-jsx": () => import("./../src/components/marketing/BlogPost/BlogPost.jsx" /* webpackChunkName: "component---src-components-marketing-blog-post-blog-post-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-features-estimated-taxes-js": () => import("./../src/pages/features/estimated-taxes.js" /* webpackChunkName: "component---src-pages-features-estimated-taxes-js" */),
  "component---src-pages-features-expense-tracking-js": () => import("./../src/pages/features/expense-tracking.js" /* webpackChunkName: "component---src-pages-features-expense-tracking-js" */),
  "component---src-pages-features-runway-js": () => import("./../src/pages/features/runway.js" /* webpackChunkName: "component---src-pages-features-runway-js" */),
  "component---src-pages-freecon-workshop-js": () => import("./../src/pages/freecon-workshop.js" /* webpackChunkName: "component---src-pages-freecon-workshop-js" */),
  "component---src-pages-freelance-starter-guide-js": () => import("./../src/pages/freelance-starter-guide.js" /* webpackChunkName: "component---src-pages-freelance-starter-guide-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-runway-calculator-js": () => import("./../src/pages/runway-calculator.js" /* webpackChunkName: "component---src-pages-runway-calculator-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

